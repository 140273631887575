<template>
    <div class="bg_404">
        <div class="bg_404_main">
            <div class="bg_404_hint">抱歉，网页走丢了！</div>
            <!-- <button class="back" @click="bacHome">回到首页</button> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
    }
</script>

<style scoped lang="less">
    .bg_404 {
        height: 100vh;
        background: rgb(251, 251, 251) url(../assets/img/404.jpg) no-repeat 50% 119px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

        &_main {
            width: 1040px;
            padding-top: 500px;
            margin: 0 auto;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        &_hint {
            height: 60px;
            padding-bottom: 14px;
            font-size: 34px;
            line-height: 60px;
            color: #00a2eb;
            padding-left: 90px;
        }

        .back {
            width: 189px;
            height: 57px;
            margin-top: 20px;
            margin-left: 125px;
            font-size: 21px;
            line-height: 57px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            background-color: #00a2eb;
            border: none;
            border-radius: 5px;
            box-shadow: 2px 2px 8px #00a2eb;
        }
    }
</style>
